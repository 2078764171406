window.SiteReady(($) => {

  console.log('Init Block: Billboard');

  let $items = $('.js-billboard');

  $items.each((index, el) => {
    let $item = $(el);
    let $body = $item.find('.js-billboard_body');
    let $media = $item.find('.js-billboard_media');

    if ($body.length && $media.length) {
      let BodySplide = $body.data('splide-data').Splide;
      let MediaSplide = $media.data('splide-data').Splide;
      let activeIndex = 0;
      let activeCount = 0;

      let bodyMoving = false;
      let mediaMoving = false;

      BodySplide.on('moved', (newIndex, oldIndex, destIndex) => {
        activeIndex = BodySplide.index;
        activeCount = BodySplide.length - 1;

        bodyMoving = false;
      });

      MediaSplide.on('moved', (newIndex, oldIndex, destIndex) => {
        mediaMoving = false;
      });

      BodySplide.on('move', (newIndex, oldIndex, destIndex) => {
        if (mediaMoving) {
          return;
        }

        bodyMoving = true;

        if (activeIndex == activeCount && destIndex == 0) {
          MediaSplide.go('>');
        } else if (activeIndex == 0 && destIndex == activeCount) {
          MediaSplide.go('<');
        } else if (destIndex > activeIndex) {
          MediaSplide.go('>');
        } else if (destIndex < activeIndex) {
          MediaSplide.go('<');
        }
      });

      MediaSplide.on('move', (newIndex, oldIndex, destIndex) => {
        if (bodyMoving) {
          return;
        }

        mediaMoving = true;

        if (activeIndex == activeCount && destIndex == 0) {
          BodySplide.go('>');
        } else if (activeIndex == 0 && destIndex == activeCount) {
          BodySplide.go('<');
        } else if (destIndex > activeIndex) {
          BodySplide.go('>');
        } else if (destIndex < activeIndex) {
          BodySplide.go('<');
        }
      });
    }

  });

});